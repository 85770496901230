import {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {getCurrentLanguageKey, getDPTAssetId, getSignInPath, isSignedIn, showError, showSuccess} from "../utils/util";
import {useTranslation} from "react-i18next";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import {loadExerciseQuestions, postExerciseResult} from "../services/exercise";
import {Text} from '@clayui/core';
import {ClayCheckbox, ClayRadio, ClayRadioGroup, ClayToggle} from '@clayui/form';
import ClayTabs from '@clayui/tabs';
import ClayButton from '@clayui/button';
import ClayForm, {ClayInput} from '@clayui/form';
import ClayProgressBar from "@clayui/progress-bar";
import QuizResultSheet from "./QuizResultSheet";
import ClayIcon from "@clayui/icon";
import ClayBadge from "@clayui/badge";
import ClayAlert from "@clayui/alert";
import SignIn from './SignIn';

const parseAnswers = (answers) => {

    return JSON.parse(answers);

}

const ExerciseView = forwardRef((props, ref) => {

    const {exerciseId, height,educationPageUrl,badgePageUrl,enableBackButton} = props;


    const {t, i18n: {changeLanguage, language}} = useTranslation();

    const [active, setActive] = useState(-1);
    const [answers, setAnswers] = useState(null);
    const [assetId, setAssetId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [scoreSheetOverall, setScoreSheetOverall] = useState(null);
    const [showError, setShowError] = useState(false);

    const prepareAnswersObject = (questions) => {

        let answersObject = {};

        questions.forEach(question => {

            switch (question.type) {

                case "selectSingleChoice": {

                    answersObject[question.id] = {};

                    answersObject[question.id] = null;

                    break;
                }
                case "selectMultipleChoice": {

                    answersObject[question.id] = {};

                    question.answers.forEach(answer => {

                        answersObject[question.id][answer.id] = false;
                    })

                    break;
                }
                case "freeText": {

                    answersObject[question.id] = "";

                    break;
                }
            }


        })

        setAnswers(JSON.stringify(answersObject));

    }

    const validateAnswers = useCallback(() => {

        let valid = true;

        Object.keys(parseAnswers(answers)).map((key, index) => {
            if (!parseAnswers(answers)[key]) {
                valid = false;
            }
        })

        setShowError(!valid);

        return valid;

    }, [answers])

    const handleClearAnswers = useCallback(()=>{

        prepareAnswersObject(questions);

    },[questions])

    const validateCurrentQuestion = useCallback((questions)=>{

        let currentQuestion = questions[active];

        let isValid = false;

        let currentAnswers = parseAnswers(answers);

        switch (currentQuestion.type){
            case "selectSingleChoice":{

                isValid = currentAnswers[currentQuestion.id] && currentAnswers[currentQuestion.id] != "";

                break;
            }
            case "freeText":{

                isValid = currentAnswers[currentQuestion.id] && currentAnswers[currentQuestion.id].length > 0?true:false;

                break;
            }
            case "selectMultipleChoice":{
                if (!currentAnswers[currentQuestion.id]){
                    isValid = false;
                    break;
                }else{
                    let answerKeys = Object.keys(currentAnswers[currentQuestion.id]);
                    for (let i = 0; i < answerKeys.length; i++) {
                        if (currentAnswers[currentQuestion.id][answerKeys[i]]){
                            isValid = true;
                            break;
                        }
                    }
                }
                break;
            }
        }

        setShowError(!isValid);

        return isValid;


    },[active,answers])

    const handleSubmit = useCallback(async () => {

        if (validateAnswers()) {

            setIsLoading(true);

            setScoreSheetOverall(await postExerciseResult(assetId, parseAnswers(answers)));

            setIsLoading(false);

        }


    }, [answers])

    const handleNextQuestion = useCallback(() => {
        if (!validateCurrentQuestion(questions)) {
            return;
        }

        setActive((prevActive) => {
            if ((prevActive + 1) < questions.length) {
                return prevActive + 1;
            }
            return prevActive;
        });
    }, [questions, validateCurrentQuestion]);

    const handlePreviousQuestion = useCallback(() => {

        if ((active - 1) >= 0) {

            setActive((active - 1));
        }

    }, [active, questions])

    const handleQuizReset = () => {

        handleClearAnswers();
        setActive(0);
        setProgress(0);
        setScoreSheetOverall(null);
    }

    useEffect(() => {
        if (assetId && assetId.toString().length > 0)
            loadExerciseQuestions(assetId).then(result => {

                setQuestions(result);

                prepareAnswersObject(result);

                setActive(0);

            });

    }, [assetId])

    useEffect(() => {

        if (questions && questions.length > 0) {

            let currentProgress = 0

            currentProgress = ((active + 1) / questions.length) * 100;

            setProgress(currentProgress);

        }

    }, [active, questions])

    useEffect(() => {

        changeLanguage(getCurrentLanguageKey()).then(() => {

        });

        if (exerciseId && exerciseId.toString().length > 0) {

            setAssetId(exerciseId);

        } else {

            setAssetId(getDPTAssetId());

        }

    }, [changeLanguage]);

    return (
        <div>
            {isLoading && (
                <ClayLoadingIndicator displayType="secondary" size="sm"/>
            )}

            {scoreSheetOverall && (
                <>

                    <QuizResultSheet handleReset={handleQuizReset} badgePageUrl={badgePageUrl} educationPageUrl={educationPageUrl} scoreSheetOverall={scoreSheetOverall}  title={"test"} ></QuizResultSheet>

                </>
            )}
            
            {!scoreSheetOverall && questions && (
                <>

                    <div className={"progressbar-container"}>

                        <div className={"progressbar-tag"}>

                            {`${t('progress-lable')} ${active + 1} ${t('of')} ${questions.length}`}

                        </div>

                        <ClayProgressBar value={progress}></ClayProgressBar>

                    </div>

                    {showError && (
                        <div className={"my-4"}>
                            <ClayAlert displayType="warning" title="Warning" role={null}>
                                {t('missing-answer')}
                            </ClayAlert>
                        </div>
                    )}

                    <ClayTabs active={active}>
                        <ClayTabs.Content activeIndex={active} className={"w-100"} fade>
                            {questions && parseAnswers(answers) && (
                                questions.map((question, index) => (
                                    <ClayTabs.TabPane aria-labelledby="tab-1" key={index}>
                                        <div className="question">
                                            <div className="question-header">
                                                <span className="component-title">{t('question')} {index + 1}</span>
                                            </div>
                                            <div style={{minHeight: height}} className="question-body">
                                                <div className="mb-4">
                                                    <Text>
                                                        {question.title}
                                                    </Text>
                                                </div>
                                                <div className="mb-3">

                                                    {question.answers && question.type === "selectSingleChoice" && (
                                                        <ClayRadioGroup key={question.id}
                                                                        value={parseAnswers(answers)[question.id]}
                                                                        onChange={(value) => {

                                                                            setAnswers((prevAnswers) => {
                                                                                let newAnswers = {...JSON.parse(prevAnswers)};
                                                                                newAnswers[question.id] = value;
                                                                                return JSON.stringify(newAnswers);
                                                                            });

                                                                        }}>
                                                            {
                                                                question.answers.map((answer, index) => <ClayRadio
                                                                    key={answer.id} label={answer.title}
                                                                    value={answer.id}/>)
                                                            }
                                                        </ClayRadioGroup>

                                                    )}

                                                    {question.answers && question.type === "selectMultipleChoice" && question.answers.map((
                                                        answer, index) =>
                                                        <div className="mb-3">
                                                            <ClayCheckbox key={answer.id} label={answer.title}
                                                                          onChange={(value) => {

                                                                              setAnswers((prevAnswers) => {
                                                                                  let newAnswers = {...JSON.parse(prevAnswers)};
                                                                                  newAnswers[question.id][answer.id] = value.target.checked;
                                                                                  return JSON.stringify(newAnswers);
                                                                              });

                                                                          }}
                                                                          checked={parseAnswers(answers)[question.id][answer.id]}/>
                                                        </div>
                                                    )}

                                                    {question.type === "freeText" && (
                                                        <div className="mb-3">
                                                            <ClayForm.Group>

                                                                <label
                                                                    htmlFor={`text_${question.id}`}>{t('your-answer')}</label>

                                                                <ClayInput
                                                                    key={question.id}
                                                                    id={`text_${question.id}`}
                                                                    component="textarea"
                                                                    placeholder={t('insert-your-answer-here')}
                                                                    type="text"
                                                                    value={parseAnswers(answers)[question.id]}
                                                                    onChange={(event) => {

                                                                        setAnswers((prevAnswers) => {
                                                                            let newAnswers = {...parseAnswers(prevAnswers)};
                                                                            newAnswers[question.id] = event.target.value;
                                                                            return JSON.stringify(newAnswers);
                                                                        });

                                                                    }}
                                                                />
                                                            </ClayForm.Group>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="question-footer">
                                                    <span>
                                                        {!isSignedIn() && (
                                                            <>
                                                                <a href={getSignInPath()}>{t('sign-in')}</a> {t('quiz-sign-in-text')}
                                                            </>
                                                        )}
                                                    </span>


                                                {enableBackButton && (
                                                    <ClayButton disabled={isLoading} displayType={"secondary"}
                                                                onClick={handlePreviousQuestion}>{t('back')}</ClayButton>
                                                )}


                                                {active === questions.length - 1 && (

                                                    <ClayButton disabled={isLoading} displayType={"primary"}
                                                                onClick={handleSubmit}>
                                                        {t('submit')}

                                                        {isLoading && (
                                                            <span className="inline-item inline-item-before mx-2">
                                                            <ClayLoadingIndicator displayType="secondary" size="sm"/>
                                                        </span>
                                                        )}

                                                    </ClayButton>

                                                )}

                                                {active < questions.length - 1 && (

                                                    <ClayButton displayType={"primary"}
                                                                onClick={handleNextQuestion}>
                                                        <span>{t('next')} </span>
                                                        <ClayIcon symbol={"order-arrow-right"}></ClayIcon>
                                                    </ClayButton>
                                                )}


                                            </div>
                                        </div>
                                    </ClayTabs.TabPane>
                                ))
                            )}
                        </ClayTabs.Content>
                    </ClayTabs>
                </>

            )}

        </div>
    );

});

export default ExerciseView;
